import React from 'react'
import { ImgProvider } from '../../Assest/ImgProvider';
export default function ServiceSection() {
  const services = [
    {
      title: "Hatha Yoga",
      description: "Hatha Yoga balances the mind and body through...",
      image: ImgProvider?.homeservice,
    },
    {
      title: "Vinyasa Yoga",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques, and meditation for well-being...",
      image: ImgProvider?.homeservice1,
    },
    {
      title: "Yin Yoga",
      description: "Meditation calms the mind and body, fostering...",
      image: ImgProvider?.homeservice2,
    },
    {
      title: "Meditation",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques, and meditation for well-being...",
      image: ImgProvider?.homeservice3,
    },
    {
      title: "Meditation yoga",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques, and meditation for well-being...",
      image: ImgProvider?.homeservice4,
    },
    {
      title: "Meditation rise",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques, and meditation for well-being...",
      image: ImgProvider?.homeservice5,
    },
 

  ];

  return (
   
      <div id="services" className="seervicsectionback">
        <div className=" max-w-7xl px-3 mx-auto md:px-4 ">
          <h2 className=' flex  text-3xl mt-5 mb-5 md:px-4 md:mt-5 md:mb-5 subhedting1' >Our Services</h2>
          <div className="servicessectboxmain items-center justify-between ">
            {services?.map((service, index) => (
              <div>
                {
                  index % 2 === 0 ? (
                    <div className="servicsectionbox">
                      {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className="card-image" /> */}
                      <img src={service?.image} alt={service?.title} className="card-image" />
                      <div className="card-overlay">
                        <h2 className='servicsectionboxhead mt-2 mb-2'>{service?.title}</h2>
                        <p className="servicsectionboxpara"> {service?.description} </p>
                      </div>
                    </div>
                  ) : (
                      <div className='servicsectionbox mt-20'>
                        <h3 className='servicsectionboxhead'>{service?.title}</h3>
                        <p className='servicsectionboxpara mt-2 mb-5'>{service?.description}</p>
                        <img src={service?.image} alt={service?.title} className='servicesectionimg' />
                        {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className='servicesectionimg' /> */}
                      </div>
           
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    
  )
}
