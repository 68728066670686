import './App.css';
import { Route, Routes, BrowserRouter} from "react-router-dom";
import React from "react";
import MainPage from './MainPage';
import NoPage from './Pages/NoPage';
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Services from './Pages/Services';
import TermsCondition from './Pages/TermsCondition';
import Faq from './Pages/Faq';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CookiePolicy from './Pages/CookiePolicy';
function App() {
  return (
    <div className="body">
    <BrowserRouter basename="/">
     
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/termscondition" element={<TermsCondition />} />
          {/* <Route path="/faq" element={<Faq />} /> */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
   
    </BrowserRouter>
    </div>
  );
}

export default App;
