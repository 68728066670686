import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function TermsCondition() {
  return (
    <>
  <Header/>
    <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Terms & Condition</div>
          <div className='subhedting'>"Clear guidelines for a seamless experience"</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
       <div className=" conatctmanis">
      <div className="boxall mt-20 mb-20">
      <div className="policy">
        <div className="d-container">
            <div className="white-box p-content">
                <h1 className="t-center">General <strong>Terms and Conditions</strong></h1>
                <h3>1. Introduction</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>This Agreement applies to every transaction between you (client) and the company.</li>
                    <li>Before any Distance transaction, the client will get a durable copy of this Agreement
                        electronically.</li>
                    <li>The company can also provide a free copy of this Agreement before making any Distance
                        transaction to the clients so that they can review the company's policy.</li>
                    <li>It is the responsibility of clients to go through this Agreement before using any of the
                        company's services. The use of any service will be taken as that the clients has read and
                        acknowledged the Agreement.</li>

                </ol>

                <p>This Agreement contains the arbitration provision which states that arbitration will be used to solve
                    any dispute other than any court proceedings or jury trails.
                </p>

                <h3>2. Definitions</h3>
                <p>In this Agreement, the following terms shall have the meanings ascribed to them below:</p>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li><b>Agreement</b> – Refers to the agreement for providing meal plans based on YogaRise.</li>

                    <li><b>Client</b> – Any individual utilizing the services provided by the Company. </li>
                    <li><b>Company </b>–  VOTARINI LTD, situated at Griva Digeni, 115, Trident Centre 3101, Limassol, Cyprus, with email support@YogaRise.com, duly incorporated under the laws of Cyprus. The Company is registered with the relevant authorities in Cyprus and operates in accordance with the laws and regulations of Cyprus.</li>
                    <li><b>Offer</b> – Represents the proposal extended by the Company to utilize its services.</li>
                    <li><b>Privacy Policy</b> – The Company's policy governing the privacy and protection of personal data.</li>
                    <li><b>Services</b> – The services provided by the Company to the Client, as outlined on the website and other informational materials.</li>
                    <li><b>Digital Content</b> – Refers to personal fasting meal plans provided by the Company.</li>
                    <li><b>Distance Contract</b> – A contractual arrangement between the Company and the Client, adhering to the guidelines established by the Company.</li>
                    <li><b>Website </b> – The official website of YogaRise, accessible at https://YogaRise.com/.</li>
                </ol>

                <h3>3. Consent To The Offer</h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>The company will will inform the client about availability of offer through company's website.
                    </li>

                    <li>The client will have to enter certain information to receive the offer. The client is
                        responsible to provide correct information on the website.</li>
                    <li>The client will receive the offer after providing certain information. The offer will include:
                        <ul style={{marginLeft:"20px"}}>
                            <li><i className="fa fa-angle-right"></i> Cost of a single meal plan</li>
                            <li><i className="fa fa-angle-right"></i> Payment options available at website</li>
                            <li><i className="fa fa-angle-right"></i> Certain important information which the company finds
                                important</li>
                        </ul>

                    </li>

                    <li>Consent to the offer – It will be taken as acceptance to the Terms &amp; Conditions once the
                        client ticks the <i>"I agree with the Terms &amp; Conditions"</i> box and press the button
                        <i>"Submit"</i>.</li>

                </ol>

                <h3>4. Distance Contract</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>The Distance contract will only be concluded once the client will accept the offer.</li>

                    <li>Once the Client accepts the offer, the company will send a confirmation receipt electronically.
                        The company will send the personal meal plans on the email ID provided on the website.</li>
                    <li>Since the digital content is provided electronically, the client can not withdraw from this
                        agreement.</li>
                    <li>Even though the company makes sure to provide instant service, some of the services are depended
                        on the internet which is not in the control of company. The company can not be held responsible
                        for any error, viruses, intrusions, malware content or any other failure.</li>
                    <li>The Company is obligated to provide digital content to the clients only for the days they have
                        paid for be it 90,180 or yearly. The services provided depend on the services opted for.</li>
                    <li>The company can change or expand its services anytime in the future and it is not responsible to
                        notice the client. The company can also terminate any service or a part of the service without
                        any obligation to the client.</li>
                    <li>The client also agrees that:</li>

                </ol>
                <ul style={{marginLeft:"20px"}}>
                    <li><i className="fa  fa-angle-right"></i> He/she is not under 18</li>
                    <li><i className="fa  fa-angle-right"></i> The client takes full responsibility for any unauthorized use
                        by minors.</li>
                </ul>

                <h3>5. Payments</h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>During the period mentioned in the Offer, the price will remain same for the Services being
                        offered , except changes in VAT-tariffs.</li>

                    <li>The Client agrees to:
                        <ul style={{marginLeft:"20px"}}>
                            <li><i className="fa  fa-angle-right"></i> Pay all the additional taxes including all the fees
                                and charges</li>
                            <li><i className="fa  fa-angle-right"></i> Use a authorized mode of payment</li>
                            <li><i className="fa  fa-angle-right"></i> Provide correct and current information on the
                                website. If the client provides a false information, the company can terminate the offer
                                anytime and cannot be held for refund.</li>
                        </ul>
                    </li>


                    <li>The Client will be transferred to some third party payment service provider and the risk
                        associated with it would pass to the Client. The company does not store any sensitive or
                        personal data. The company can not be held responsible for any third party payment disputes.
                    </li>
                    <li>All prices and costs mentioned on the website are in US Dollars.</li>
                </ol>
                <p><strong>Refund Policy</strong></p>
                <p>The Company doesn't claim or follow a refund policy, unless the digital product in the form of a meal
                    plan including recipes, is anyhow proven to be not as described. And if such cases happen, the
                    Client is supposed to contact our customer support at <b className="mail">support@YogaRise.com</b> and
                    provide detailed information proving the Company’s product not as described. Once the requested
                    refund is issued, the Client won't have the access to the Company’s services. All refunds are
                    subjected to the original method of payment. On the purchase of digital services, the Client agrees
                    to this refund policy and withdraws any rights to subject it to any questions, judgment, or legal
                    actions.</p>
                <p>
                    The company declares that local charges (taxes etc.) may occur, depending on your region and tax
                    laws. These charges are supposed to be owned by the customers, not the company.</p>


                <h3>6. Intellectual Property Rights</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>All the trademarks rights, design rights, copyright and patent rights are owned by the company
                        can not not be used otherwise.</li>
                    <li>The client can not disassemble, reproduce, distribute, publish any of the company's property in
                        part or in whole without company's consent.</li>
                    <li>The Client grants to Company a fully paid up, royalty-free, non exclusive license to reproduce,
                        display, distribute, modify, or use the client's content to the extent permitted by law. User
                        content includes – images, communications, creative works, writings or any User Trademarks.</li>
                    <li>No component part of this Agreement can be interpreted as a transfer of intellectual property.
                    </li>
                </ol>

                <h3>7. Use Of Digital Content</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>All intellectual property rights or any digital content are owned by the Company and the client
                        can only use a limited, non-exclusive or non transferable content provided by Company.</li>
                    <li>These Terms &amp; Conditions are applicable for a term of 5 years from the date of offer unless
                        earlier suspended or terminated.</li>
                    <li>The client shall not lend, reproduce or edit the Digital content for any third party's use.</li>
                    <li>The company can limit the number of devices on which the digital content can be used.</li>
                    <li>If the clients breach any of the rules, the Company may debar access to the in-dispute Digital
                        content.</li>
                </ol>

                <h3>8. Sale of Digital Content Prohibited</h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>The client can not sale, offer to sale, share, rent or lend the digital content of the website.
                    </li>

                </ol>

                <h3>9. Privacy Policy</h3>

                <p>
                    Client’s data is governed by the Privacy Policy which you can found on the Website. Client should
                    have a printed copy of this agreement alongside Privacy Policy.
                </p>
                <h3>10. Indemnity</h3>
                <p>The client agrees to indemnify the company and its officers, directors affiliates, agents, employees,
                    legal representatives, subsidiaries or suppliers from any sort of claim or demand which may arise
                    due to the violation of Privacy policy.</p>
                <h3>11. Liability</h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>It is the sole responsibility of client to verify all the information mentioned on the website.
                        Some of the information may come as inappropriate or unsatisfactory. Any decisions made on the
                        basis of the information mentioned on the website will be their own and they can not held the
                        website responsible.</li>
                    <li>The client agrees that the company can not be held liable for any damage whatsoever even if the
                        company was informed and advised of any such damage. The company’s aggregate liability shall not
                        exceed one hundred dollars ($100). The damage may happen due to:
                        <ul style={{marginLeft:"20px"}}>
                            <li><i className="fa  fa-angle-right"></i> Inability to use the services</li>
                            <li><i className="fa  fa-angle-right"></i> Information provided on the website</li>
                            <li><i className="fa  fa-angle-right"></i> Misinterpretation of any services</li>
                        </ul>
                    </li>
                    <li>The Company shall not be held responsible for any loss caused by the riot, war or any natural
                        disaster or due to any such occurrences for which the Company is not responsible like traffic
                        hold-ups, strike, lock-out etc.</li>
                    <li>The liability of the company is directly proportional to the individual loss unless provided by
                        the applicable laws.</li>
                    <li>The company takes no guarantee for any results or outcome using the company's services as
                        individual results may vary and it depends on the client's adherence to work.</li>
                    <li>The Website may contain links which may direct the user to other websites which are not
                        controlled by the Company. The company is not responsible for the content mentioned on other
                        websites or any loss caused by following those websites. </li>
                </ol>
                <h3>12. Medical Disclaimer</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Before following any meal plan mentioned by the company, the client should consult with his/her
                        doctor first.</li>
                    <li>The company is not related with any medical organization neither it provides any medical
                        assistance. The services offered by the company can not be interpreted as medical advice. The
                        client will be held responsible to misinterpret any information as medical assistance. </li>
                </ol>
                <h3>13. Validity and Termination</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>This Agreement will be effective as soon as the Client accepts and expresses consent to follow
                        it. They will remain in effect unless terminated by the company.</li>
                    <li>The Company can terminate the relationship with Client at any time if:</li>
                </ol>
                <ul style={{marginLeft:"20px"}}>
                    <li><i className="fa  fa-angle-right"></i> The Client does not agree with this Agreement</li>
                    <li><i className="fa  fa-angle-right"></i> Client breaches this Agreement</li>
                    <li><i className="fa  fa-angle-right"></i> Client provides incorrect information </li>
                </ul>
                <h3>14. Changes to Agreement</h3>
                <p>This Agreement, Terms &amp; Conditions and privacy policy are subject to change. The company can add,
                    modify or remove any item from the website and will notify the clients. The company may ask the
                    client to acknowledge the updates by agreeing on new Terms and Conditions.
                </p>
                <h3>15. Communication</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Company's preferred mode of communication is Email. The client should have a valid and active
                        email ID to maintain communication with the website and to know about the latest updates of the
                        website. The client is requested to check the email regularly to follow the website.</li>
                    <li>If it is required to provide the information on a durable platform, the company will send the
                        email with an downloadable attachment which the client can save it on their person. It will be
                        the responsibility of client to save the documents for any future reference.</li>
                    <li>The clients can request a durable copy of this Agreement or any other document by contacting <b className="mail">admin@YogaRise.com</b>.</li>
                    <li>The official language of website is English and all the communication with client will be made
                        in English, unless the concerned party prefers to communicate in another language.</li>
                    <li>The Client can contact the website any time by sending a mail to <b className="mail">admin@YogaRise.com</b>.</li>
                </ol>
                <h3>16. Dispute Resolution</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li><b>Governing Law</b> – This Agreement is governed by the laws of Cyprus, irrespective of the Client’s location.</li>
                    <li><b>Informal Dispute Resolution</b> – Any dispute or complaint regarding the Company's services should be initially addressed by contacting  <b className="mail">admin@YogaRise.com</b>. The Company will acknowledge receipt of the complaint within 14 days and aims to resolve the issue within 30 days. If the matter remains unresolved after this period, the Client may pursue formal resolution.</li>
                    <li><b>Arbitration</b> – All disputes and claims shall be resolved through neutral arbitration, rather than in court or by jury. Some disputes may be eligible for resolution through Small Claims Court.
The Client agrees that arbitration for the dispute will be conducted on an individual basis, and class arbitrations are not permitted.
                    </li>
                    <li>The Client may opt out of arbitration by sending an email to admin@YogaRise.com with their name and address within 30 days of accepting the offer.</li>
                    <li>Arbitration proceedings will be administered by an arbitration institution approved in Cyprus under the Consumer Arbitration Rules. The Client may access these Rules online at  https://www.adr.org/consumer. The arbitrator’s decision shall be final and binding.</li>
                </ol>

                <h3>17. Miscellaneous</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Clients can not assign rights of this Agreement to a third party without having the prior
                        consent of the Company. The Company can assign its rights and obligations mentioned here to any
                        third party.</li>
                    <li>If a part of this Agreement is found to be unlawful, invalid, or unenforceable then that part
                        shall be deleted from the the Agreement, which shall act to be valid.</li>
                    <li>The use of any of the services mentioned on the website is solely at client's own risk. The
                        company offers no warranty that the website or services mentioned in it will meet client's
                        requirements and expectations, or would be secure, uninterrupted, timely, accurate or, complete.
                        The company does not claim to cure any disease nor to prevent one. If the client is unsatisfied
                        with the website, he/she can cease to use it. </li>
                    <li>By accessing the website or using the services offered in it, the client hereby acknowledges and
                        agrees that he/she has read the agreement and agrees to follow its terms and conditions. </li>

                </ol>

                <h3>18. Plan Terms &amp; Condition</h3>

                <p>When you Agree to the Terms &amp; Conditions and Privacy policy you also agree to be <b>charged $38/
                        every three months</b> if you chose this plan or <b>$49 every six months</b> if you chose a
                    six-month plan. You will be <b>charged $75 every year if you go with a yearly program</b> with us.
                    You will be <b>charged now and every 90,180,365 days as per the program you chose</b>. The charges
                    will be deducted until you cancel the subscription or when the subscription will automatically
                    expire. You will receive an electronic notification 3 to 5 days prior to any transaction and a
                    receipt after each successful transaction. You can cancel anytime by simply
                    mailing&nbsp;support@YogaRise.com for the cancelation or refund. You can talk to the support through
                    our Chat system too for quicker solutions.</p>

            </div>

        </div>
    </div>
      </div>
      </div>
    <Footer1/>
    </>
  )
}
