import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function PrivacyPolicy() {
  return (
    <>
    <Header/>
    <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Privacy Policy</div>
          <div className='subhedting'>"Your privacy is our top priority"</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
       <div className=" conatctmanis">
      <div className="boxall mt-20 mb-20">
      <div className="policy">
        <div className="d-container">
            <div className="white-box p-content">
                <h1 className="t-center"><strong>Privacy Policy</strong></h1>
                <p>In this privacy policy we, YogaRise, VOTARINI LTD, Griva Digeni, 115, Trident Centre 3101, Limassol, Cyprus. state how we handle the personal data (email-Id)
                    when someone use our app, visits our website, contact us or uses our services.
                </p>
                <p>You will be asked to agree to our use of our cookies when you first visit our website. Here you will
                    find the answer for the following: </p>
               <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                    <li>How we use user's data</li>
                    <li>Why we provide data to third parties</li>
                    <li>For how long we store the data </li>
                    <li>Our marketing policy </li>
                    <li>Your data rights</li>
                    <li>Our cookies use</li>
                </ul>
                <p>If you have any inquiry or you want to make a request, you can contact us by email. Just send an
                    email on <a href="#">admin@YogaRise.com</a> your query will be resolved shortly. All the terms
                    used here are same as mentioned in company's Terms and Conditions unless expressed otherwise. This
                    privacy policy is an integral part of company's Conditions and unless there is a different version
                    comes, this English version will prevail.</p>
                <h3>1. How Do We Use User's Data? </h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>
                        This section will provide the following information:
                        <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                            <li>Type of data we collect</li>
                            <li>The source of data collected</li>
                            <li>The purpose for which the data is collected</li>
                            <li>Legal rules related with it</li>
                        </ul>
                    </li>
                    <li>We process information which you provide to us by filling several questionnaires. The data can
                        be your name, email address, account transaction details and other information which you provide
                        willingly. The service data is used to provide better website experience also and to make things
                        like signing up, easy. When you agree to our terms and conditions, you give consent to collect
                        your data.</li>
                    <li>We may process your messaging data which you provide by subscribing to us. It may be used to
                        provide you relevant offers concerning your interest. These mails will be sent only with your
                        consent and you can always opt out from those messages. The company can also send mails to
                        improve customer relations.</li>
                    <li>We may process any information that you sent to us by maintaining communication with us. The
                        main motive of this correspondence data is to maintain communication with you and to record your
                        data. It can be used to improve our website and maintaining proper administration of our
                        business. It can also be used to dissolve disputes between you and one of our employees.</li>
                    <li>We may process your device data on which you use our website. It may include IP address, screen
                        resolution, device type and location (in some cases). These kind of data are collected through
                        cookies. This data is used to know more about how you use our website and how can we secure your
                        website experience. The legal reason to collect this data is to improve our website and user
                        experience.</li>
                    <li>We may process some of your personal data (email-Id) which you provide willingly on the website.
                        We do not share this kind of data with anyone except whenever demanded by a court order. This
                        data is collected to secure user's and our legal rights.</li>
                    <li>We may also collect your personal data (email-Id) to comply with law to protect our and your
                        vital interest.</li>
                </ol>
                <h3>2. When We Disclose personal data (email-Id) To Others?</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>We can or may share your data with any partner company of our group. It can be our subsidiary
                        branch or any of the holding company.</li>
                    <li>We may share your personal data (email-Id) with a insurer or any professional advisers for the
                        purposes of maintaining insurance coverage, obtaining professional advice, managing risks, or to
                        manage legal claims either in court or in out of court dispute.</li>
                    <li>We may share the personal data (email-Id) with an anti-fraud company in order to protect your
                        personal data (email-Id).</li>
                    <li> We may share the personal data (email-Id) with our payment service providers to process the
                        payments and manage the funds.</li>
                    <li>We may share the personal data (email-Id) with a service providers to maintain several services
                        of our website such email service, research service and such.</li>
                    <li>We may share your personal data (email-Id) whensoever it is required by law to meet any legal
                        obligation I order to secure our interest.</li>
                    <li>To get more information regarding our safeguards, you can mail us at <a href="#">admin@YogaRise.com</a>.</li>
                </ol>
                <h3>3. The Duration For Which We Store Your Data?</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>
                        Your personal data (email-Id) is stored for as long as it is necessary to maintain legal
                        records. The time duration for different sort of data is as follows:
                       <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                            <li>Service data is stored for no longer than 5 years</li>
                            <li>Messaging data is stored for no longer than 2 years</li>
                            <li>Correspondence data is stored for no longer than 6 months</li>
                        </ul>
                    </li>
                    <li>It is not necessary to specify the data by us and some of the data is kept for the relevant
                        processing period.</li>
                    <li>We are obliged to store some personal data (email-Id) to meet any legal obligation which may
                        arise in the future.</li>
                </ol>

                <h3>4. Marketing Messages</h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>With your consent, we will send marketing mails and messages which may contain our latest
                        services and offers.</li>
                    <li>You can choose to opt out of any messages before or during the service.</li>
                    <li>If you opt out of any communication, you will get a message stating that you will not get
                        marketing messages in future.</li>
                    <li>Due to the closely related network services, you may get marketing messages for a while until
                        the website processes your request.</li>
                    <li>Stopping marketing messages won't stop you from receiving service related communications.</li>
                </ol>
                <h3>5. Your Rights</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Here are your summarized rights which comes under the Data Protection Law. It is a brief summary
                        of such laws and we request you to read the detailed version which you can get from the
                        regulation authorities.</li>
                    <li>
                        You have the following rights:
                       <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                            <li>Right to request to delete your personal data (email-Id)</li>
                            <li>Right to access data</li>
                            <li>Right to rectification</li>
                            <li>Right to set a processing limit on your personal data (email-Id)</li>
                            <li>Right to object on processing of your data</li>
                            <li>Right to complain to the authority</li>
                            <li>Right to withdraw consent</li>
                            <li>Right to data portability</li>
                        </ul>
                    </li>
                    <li>You have the right to access your personal data (email-Id) and you can know the details of
                        processing of data. You can get a hard copy of your personal data (email-Id) free of charge for
                        the first time but you have to pay for it for any further copies.</li>
                    <li>You have the right to rectify any data which you find to be incorrect. </li>
                    <li>You have the right to ask to delete all your personal data (email-Id). It normally happens when
                        the user withdraw his/her consent, the user terminates the service or it is required by law.
                    </li>
                    <li>You have the right to restrict the processing of your data. It normally happens when you do not
                        give your consent to process your data or find your personal details to be inaccurate.</li>
                    <li>You have the right to uphold you consent to process your data for any marketing purpose. Once
                        you object for it, your data won't be provided for any marketing purpose.</li>
                    <li>You have the right to uphold you consent to process your data for any statistical, historical or
                        scientific research purpose. Once you object for it, your data won't be provided for any such
                        research works.</li>
                    <li>You have the right of data portability. You can receive a electrically readable document
                        containing your structured personal data (email-Id).</li>
                    <li>If you think that your data is being mistreated, you have the right to file a complaint with the
                        supervisory authority obligated for data protection.</li>
                    <li>You can use any of the rights mentioned here by contacting us at <a href="#">admin@YogaRise.com</a>.</li>
                </ol>
                <h3>6. Cookies</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Cookies are those textual files which contains identifier which is sent by the web server to the
                        web browser and is further filed by the browser. It is then sent back to the server whenever the
                        browser requests a pre-loaded page from the server.</li>
                    <li>Cookies do not contain personally identifiable information but some of the information might
                        link itself from the information we store.</li>
                </ol>
                <h3>7. Cookies That We Use</h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>We use three types of cookies on our website. They are:</li>
                </ol>
               <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                    <li><b>Functional Cookies</b> – To enhance the user experience and to improve services</li>
                    <li><b>Required Cookies</b> – To make sure the website run smoothly and follows all the security
                        concerns</li>
                    <li><b>Advertising Cookies</b> – To observe user's online behavior to to provide optimized marketing
                        information </li>
                </ul>
                <h3>8. Cookies Used By Our Service Providers </h3>
                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>Our service providers uses cookies and some of those might be stored on your system.</li>
                    <li>We use the following cookies:</li>
                </ol>
               <ul style={{ listStyleType:"number",marginLeft:"20px"}}>
                    <li>YouTube cookies to display YouTube content on our website. Click here to read YouTube's privacy
                        policy. </li>
                    <li>Google Analytics cookies to monitor our website traffic. It helps us find website errors and to
                        measure website bandwidth. Click here to read Google Analytics' privacy policy.</li>
                    <li>Twitter cookies to display Twitter content posted on our website. Click here to read Twitter's
                        privacy policy. </li>
                    <li>Double-click cookies to show ads on our website. These cookies helps us identify our users who
                        uses any of our services. Click here to read Double-click privacy policy.</li>
                    <li>Google Maps cookies to determine user location. You can opt to ignore it if you do not want to
                        share your location with use. These cookies helps us know about the user's location. Click here
                        to read Google Map's privacy policy.</li>
                    <li>Google Tag Manager cookies to manage advertising cookies. These cookies helps us display the
                        best suited ads to the users. Click here to read Google Tag Manager privacy policy.</li>
                    <li>Facebook cookies to show ads on our website. These cookies helps us identify our users who uses
                        any of our services. Click here to read Facebook's privacy policy.</li>
                    <li>Hotjar cookies to know about how a user uses our website. These cookies helps us manage our
                        website and to enhance user experience. Click here to read Hotjar's privacy policy.</li>
                </ul>

                <h3>9. How Can You Manage Cookies? </h3>

                <ol style={{listStyleType: "lower-alpha",marginLeft:"20px"}}>
                    <li>You can refuse the use of cookies on the browser you are using and also delete the already
                        existing cookies. You can find the relevant information on any of the browser such as Firefox,
                        Chrome, Safari or Internet Explorer.</li>
                    <li>If you block cookies on all the websites, you might be able to use some websites effectively.
                    </li>
                    <li>Blocking the cookies will also impact your user experience on our website and you won't be able
                        to use many features.</li>
                </ol>

                <h3>10. Third Party Websites </h3>
                <p>Our website includes several links which may be from our partner sites or any third party websites.
                    Keep in mind that we do not control those websites and they have their own set of privacy policy.
                    Please ensure all the aspects before going into any transaction with them. Under no circumstances
                    you can held us responsible for any loss which you might face using any third party services</p>

                <h3>11. Updating Your Data</h3>
                <p>If you think that you have provided some incorrect data, contact us with the correct data and we
                    would update it.</p>

                <h3>12. Update And Changes To The Notice</h3>
                <p>We will inform our users to any updates or changes made here via email and it will also be published
                    on the website.</p>
            </div>

        </div>
    </div>
      </div>
      </div>
    <Footer1/>
    </>
  )
}
