import { React, useEffect } from 'react'
import { ImgProvider } from '../Assest/ImgProvider'
import { Link, useLocation } from 'react-router-dom';
export default function Footer() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [pathname]);

  const footerLinks = [
    { path: '/about', label: 'About Us' },
    { path: '/services', label: 'Our Services' },
    { path: '/cookiepolicy', label: 'Cookie Policy' },
    { path: '/privacypolicy', label: 'Privacy Policy' },
    { path: '/termscondition', label: 'Terms & Condition' },
    // Uncomment the following lines if you want to add additional links
    // { path: '/faq', label: 'FAQs' },
    // { path: '/contact', label: 'Contact Us' },
  ];
  return (
    <div className='footerbox '>
      <div className='mt-20 max-w-7xl px-5 md:px-5 mx-auto'>
        <div className='flex grid gap-3 md:gap-4 md:grid-cols-2'>
          <div>
            <div className='headtex'>Lets Talk!<br /> Book an appointment</div>
            <div className='paramtex mt-2 mb-10'>Have questions or need support? Reach out to us!
              We're here to help with any inquiries about our app, services,
              or personalized plans. Contact us anytime for  assistance
              and guidance.</div>
            <Link to="/contact">
              <div className='bannerbuttonfoot mt-10 mb-2'>Contact Us </div></Link>
          </div>
          <div className='flex ' style={{ justifyContent: "flex-end" }} >
            <img src={ImgProvider?.contact} alt="contact" style={{ height: '250px' }} className='bbbb1'/>
          </div>
        </div>
      </div>
      <div className="max-w-7xl px-2 py-3 mx-auto tracking-wide mt-10 mb-5 md:px-4 md:mt-5 md:mb-5">
        <div className="flexwebfoot grid  py-8 text-lg leading-6 text-gray-800  md:grid-cols-7 sm:grid-cols-1">
          {footerLinks.map((link, index) => (
            <div className={`space-y-3 ${index > 0 ? 'lg:border-l-2 lg:border-white' : ''}`}>

              <Link to={link.path}>
                <div className="mfooter">{link.label}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className='menulay1 mb-5'>
        <div className='soiliconbox'><i className='fa fa-twitter' /></div>
        <div className='soiliconbox'><i className='fa fa-pinterest' /></div>
        <div className='soiliconbox'><i className='fa fa-facebook' /></div>
        <div className='soiliconbox'><i className='fa fa-instagram' /></div>
      </div>
      <div className='coputext mb-5'>
        Copyright © 2024 YogaRise.com
      </div>
    </div>
  )
}
