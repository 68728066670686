import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function CookiePolicy() {
  return (
    <>
   <Header/>
    <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Cookie Policy</div>
          <div className='subhedting'>"Confidence with integrity, always"</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
       <div className=" conatctmanis">
      <div className="boxall mt-10 mb-10">
      <div className="policy">
        <div className="d-container">
            <div className="white-box p-content">
                <h1 className="t-center">Cookie Policy of <strong>YogaRise.com</strong></h1>
                <p>Cookies and other Identifiers consist of portions of code installed in the browser that assist the
                    Owner in providing the Service according to the purposes described. Some of the purposes for which
                    Cookies are installed may also require the User's consent.</p>

                <p>Where the installation of Cookies is based on consent, such consent can be freely withdrawn at any
                    time following the instructions provided in this document.</p>
                <h3 className="mb-2">Technical Cookies and Cookies serving aggregated statistical purposes</h3>
                <p><strong>Activity strictly necessary for the functioning of the Service</strong><br/>
                    This Application uses Cookies to save the User's session and to carry out other activities that are
                    strictly necessary for the operation of this Application, for example in relation to the
                    distribution of traffic.</p>

                <p><strong>Activity regarding the saving of preferences, optimization, and statistics</strong><br/>
                    This Application uses Cookies to save browsing preferences and to optimize the User's browsing
                    experience. Among these Cookies are, for example, those used for the setting of language and
                    currency preferences or for the management of first party statistics employed directly by the Owner
                    of the site.</p>

                <h3>How to provide or withdraw consent to the installation of Cookies</h3>
                <p>In addition to what is specified in this document, the User can manage preferences for Cookies
                    directly from within their own browser and prevent – for example – third parties from installing
                    Cookies. Through browser preferences, it is also possible to delete Cookies installed in the past,
                    including the Cookies that may have saved the initial consent for the installation of Cookies by
                    this website. Users can, for example, find information about how to manage Cookies in the most
                    commonly used browsers at the following addresses: Google Chrome, Mozilla Firefox, Apple Safari and
                    Microsoft Internet Explorer.</p>

                <p>With regard to Cookies installed by third parties, Users can manage their preferences and withdrawal
                    of their consent by clicking the related opt-out link (if provided), by using the means provided in
                    the third party's privacy policy, or by contacting the third party.</p>

                <p>Notwithstanding the above, Users are hereby informed that they may follow the instructions provided
                    by YourOnlineChoices (EU), the Network Advertising Initiative (US) and the Digital Advertising
                    Alliance (US), DAAC (Canada), DDAI (Japan) or other similar services. Such initiatives allow Users
                    to select their tracking preferences for most of the advertising tools. The Owner thus recommends
                    that Users make use of these resources in addition to the information provided in this document.</p>

                <h3>Owner and Data Controller</h3>
                <p>VOTARINI LTD<br/>
				Griva Digeni, 115, Trident Centre 3101,
					Limassol, Cyprus<br/>

                    <strong>Owner contact email:</strong> <a href="mailto:admin@YogaRise.com">admin@YogaRise.com</a>
                </p>

                <p>Since the installation of third-party Cookies and other tracking systems through the services used
                    within this Application cannot be technically controlled by the Owner, any specific references to
                    Cookies and tracking systems installed by third parties are to be considered indicative. In order to
                    obtain complete information, the User is kindly requested to consult the privacy policy for the
                    respective third-party services listed in this document.</p>

                <p>Given the objective complexity surrounding the identification of technologies based on Cookies, Users
                    are encouraged to contact the Owner should they wish to receive any further information on the use
                    of Cookies by this Application.</p>
                <hr/>
                <h3>Definitions and legal references</h3>
                <p><strong>Personal Data (or Data)</strong><br/>
                    Any information that directly, indirectly, or in connection with other information — including a
                    personal identification number — allows for the identification or identifiability of a natural
                    person.</p>

                <p><strong>Usage Data</strong><br/>
                    Information collected automatically through this Application (or third-party services employed in
                    this Application), which can include: the IP addresses or domain names of the computers utilized by
                    the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the
                    request, the method utilized to submit the request to the server, the size of the file received in
                    response, the numerical code indicating the status of the server's answer (successful outcome,
                    error, etc.), the country of origin, the features of the browser and the operating system utilized
                    by the User, the various time details per visit (e.g., the time spent on each page within the
                    Application) and the details about the path followed within the Application with special reference
                    to the sequence of pages visited, and other parameters about the device operating system and/or the
                    User's IT environment.</p>

                <p><strong>User</strong><br/>
                    The individual using this Application who, unless otherwise specified, coincides with the Data
                    Subject.</p>

                <p><strong>Data Subject</strong><br/>
                    The natural person to whom the Personal Data refers.</p>

                <p><strong>Data Processor (or Data Supervisor)</strong><br/>
                    The natural or legal person, public authority, agency or other body which processes Personal Data on
                    behalf of the Controller, as described in this privacy policy.</p>

                <p><strong>Data Controller (or Owner)</strong><br/>
                    The natural or legal person, public authority, agency or other body which, alone or jointly with
                    others, determines the purposes and means of the processing of Personal Data, including the security
                    measures concerning the operation and use of this Application. The Data Controller, unless otherwise
                    specified, is the Owner of this Application.</p>

                <p><strong>This Application</strong><br/>
                    The means by which the Personal Data of the User is collected and processed.</p>

                <p><strong>Service</strong><br/>
                    The service provided by this Application as described in the relative terms (if available) and on
                    this site/application.</p>

                <p><strong>European Union (or EU)</strong><br/>
                    Unless otherwise specified, all references made within this document to the European Union include
                    all current member states to the European Union and the European Economic Area.</p>

                <p><strong>Legal information</strong><br/>
                    This privacy statement has been prepared based on provisions of multiple legislations, including
                    Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
                <p>

                    This privacy policy relates solely to this Application, if not stated otherwise within this
                    document.</p>
                <hr/>
                <small>Latest update: November 1, 2024

                </small>
            </div>

        </div>
    </div>
      </div>
      </div>
    <Footer1/>
    </>
  )
}
