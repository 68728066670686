import React, { useState, useEffect } from 'react';
import { ImgProvider } from '../../Assest/ImgProvider';

export default function TestimonialsSection() {

    const testimonials = [
   
       
          {
            name: "Emma W.",
            rating: ImgProvider?.rating,
            image:ImgProvider?.testclient,
            review:"YogaRise has been a game-changer for my wellness journey! The customized routines align perfectly with my hectic day, and the meditations help me stay grounded. Highly recommended for anyone aiming to elevate their body and mind!"
          },
          {
            name: "Sophia L.",
            rating: ImgProvider?.rating,
            image:ImgProvider?.testclient1,
            review:"Absolutely love the personalized sessions! They’ve helped me fit yoga into my busy lifestyle and stay consistent. The meditations are amazing for unwinding after a long day!"
          },
        {
          name: "Daniel K.",
          rating: ImgProvider?.rating,
          image:ImgProvider?.testclient2,
          review:"This app has transformed my yoga practice! The personalized plans fit my busy schedule, and I love the guided meditations. Highly recommend for anyone looking to improve their physical and mental well-being."
        },
        {
          name: "Lily P.",
          rating: ImgProvider?.rating,
          image:ImgProvider?.testclient,
          review: "This app has completely transformed how I approach yoga. The instructors are so encouraging, and the classes are structured just right. My flexibility and focus have improved so much!"      },
        {
          name: "Alex R.",
          rating: ImgProvider?.rating,
          image: ImgProvider?.testclient1,
          review:"Incredible app! The custom yoga plans have made it so easy to get started, and I already feel more centered and relaxed. A must-have for anyone wanting to make wellness a priority."
                   }
      ];
      
  return (
 
    <div id="testimonial" style={{background:"#CFECEC",padding:"10px"}}>
  <div className=" max-w-7xl px-2  mx-auto   md:px-4  " >
      <h2 className='subhedting1 mb-5 mt-5' style={{textAlign:"center"}}>What our clients say</h2>
      <div className="textboxman textboxmanin mt-10 mb-10">
        {testimonials.map((testimonial, index) => (
          <div className="testbox" key={index}>
            <div className="textboxman">
            {/* <img src={ImgProvider?.testclient} alt={testimonial.name} className="clientimage" /> */}
             <img src={testimonial?.image} alt={testimonial.name} className="clientimage" /> 
            <div>
            <h3 className='testcontent'> {testimonial.name}</h3>
             <div className="testrating"><img src={testimonial.rating}alt={testimonial.name}  /> </div> 
            </div>
            </div>
            <p className='testcontent'>{testimonial.review}</p>
          
          </div>
       
        ))}
      </div>
     
    </div>
    </div>
   
  )
}
